<template>
  <div>
    <v-container class="home-hero" fluid fill-height style="min-height: 70vh">
      <v-container d-flex>
        <v-row class="justify-center align-center flex-md-row-reverse">
          <v-col cols="12" md="6">
            <v-container>
              <div>
                <div
                  class="display-2 font-weight-black white--text text-left"
                  style="text-shadow: 2px 2px 8px #000000"
                >
                  <span>We strive to provide, </span>
                </div>
                <div
                  class="display-2 font-weight-black white--text text-left"
                  style="text-shadow: 2px 2px 8px #000000"
                >
                  <span>only the best scaffolding</span>
                </div>
                <div
                  class="display-2 font-weight-black white--text text-left mb-3"
                  style="text-shadow: 2px 2px 8px #000000"
                >
                  <span>equipment and services</span>
                </div>
                <div
                  class="display-1 font-weight-bold white--text text-left"
                  style="text-shadow: 2px 2px 8px #000000"
                >
                  <span style="color: #d6ebff"
                    >Here you can learn more about Concraft Scaffolding</span
                  >
                </div>
              </div>
            </v-container>
          </v-col>
          <v-col justify="center" align="center" cols="12" md="6">
            <v-img
              max-width="75vw"
              contain
              src="https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/g3107.webp?alt=media&token=91576674-6ac1-4475-a9a0-5895ac3129a2"
            >
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!-- BEE rating -->
    <v-container fluid fill-height style="min-height: 45vh; max-width: 1024px">
      <v-container d-flex>
        <v-row class="justify-center align-center ml-16">
          <v-col cols="12" md="6">
            <v-container>
              <div>
                <div
                  class="display-2 font-weight-black text-left"
                  style="text-shadow: 2px 2px 8px #000000; color: #f80000"
                >
                  <span style="font-family: BIRDMAN">Concraft </span>
                </div>
                <div
                  class="display-2 font-weight-black white--text text-left"
                  style="text-shadow: 2px 2px 8px #000000"
                >
                  <span> is proud of a Level 2 BEE Rating</span>
                </div>

                <div
                  class="headline font-weight-bold white--text text-left"
                  style="text-shadow: 2px 2px 8px #000000"
                >
                  <span style="color: #d6ebff"
                    >We are proudly a 51% black-owned enterprise</span
                  >
                </div>
              </div>
            </v-container>
          </v-col>
          <v-col justify="center" align="center" cols="12" md="6">
            <v-card min-width="100%" elevation="0" color="transparent">
              <v-img
                max-width="360px"
                contain
                src="https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/bee2.webp?alt=media&token=1a8392c3-6024-4804-a574-583f114af836"
              >
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-container
      class="about-background"
      fluid
      fill-height
      style="min-height: 45vh"
    >
      <v-container d-flex style="max-width: 1024px" class="mt-sm-12">
        <v-row class="justify-center align-center ml-16 flex-md-row-reverse">
          <v-col cols="12" md="6">
            <v-container>
              <div>
                <div
                  class="display-2 font-weight-black text-left"
                  style="text-shadow: 2px 2px 8px #000000; color: #008ae0"
                >
                  <span style="font-family: BIRDMAN">Concraft</span>
                </div>
                <div
                  class="display-2 font-weight-black white--text text-left"
                  style="text-shadow: 2px 2px 8px #000000"
                >
                  <span>employs a total of 120 people</span>
                </div>

                <div
                  class="headline font-weight-bold white--text text-left"
                  style="text-shadow: 2px 2px 8px #000000"
                >
                  <span style="color: #d6ebff"
                    >We love our team and our team loves us</span
                  >
                </div>
              </div>
            </v-container>
          </v-col>
          <v-col justify="center" align="center" cols="12" md="6">
            <v-card min-width="100%" elevation="0" color="transparent">
              <v-img
                max-width="280px"
                contain
                src="https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/120people.webp?alt=media&token=629d98b5-151a-4612-bd19-74757550d6a4"
              >
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-img class="positionleft"></v-img>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "HomeHero",
};
</script>

<style scoped>
.hero {
  display: block;
  box-sizing: border-box;
  height: 500px;
  background-color: #3d99dc;
  clip-path: ellipse(162% 100% at 39.92% 0%);
}
.home-hero {
  background: url("https://images.unsplash.com/photo-1504964670878-71b73cec0ce1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1164&q=80");
  background-size: cover;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 1000px rgba(0, 43, 99, 0.2);
  clip-path: ellipse(162% 100% at 39.92% 0%);
}

.about-background {
  /* background: url("https://images.unsplash.com/photo-1504964670878-71b73cec0ce1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1164&q=80"); */
  background-color: red;

  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 1000px rgba(0, 43, 99, 0.2);
  clip-path: ellipse(85% 56% at 51% 63%);
}
.gradient-text {
  background-image: linear-gradient(
    60deg,
    rgb(3, 124, 230),
    rgb(117, 177, 230)
  );
  background-clip: text;
  color: transparent;
}
.positionleft {
  z-index: -1;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20vh;
  position: absolute;
  background: url("https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/path7471.webp?alt=media&token=28b1465f-64e8-4889-9588-87dc0389cdfa");
  background-size: contain;
}
</style>
