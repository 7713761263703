<template>
  <TeamHero/>
</template>

<script>
import TeamHero from "@/components/TeamHero";
export default {
  created () {
  },
  components: {
    TeamHero,
  },

}
</script>

<style>

</style>