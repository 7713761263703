<template>
  <v-container class="home-hero" fluid fill-height  style="min-height: 70vh">
    <v-container d-flex >
      <v-row  class="justify-center align-center ">
        <v-col cols="12" md="6" >
          <v-container>
         <div >
             <div class="display-2 font-weight-black white--text text-left" style="text-shadow: 2px 2px 8px #000000">
          <span 
            >We know it's difficult,   </span
          >
        </div>
        <div class="display-2 font-weight-black white--text text-left" style="text-shadow: 2px 2px 8px #000000">
          <span 
            >to discern between whether</span
          >
        </div>
        <div class="display-2 font-weight-black white--text text-left mb-3" style="text-shadow: 2px 2px 8px #000000">
          <span 
            >what we say is true or not</span
          >
        </div>
        <div class="display-1 font-weight-bold white--text text-left" style="text-shadow: 2px 2px 8px #000000">
          <span style="color:  #d6ebff"
            >But here are some images to prove our bounds of work done</span
          >
        </div>
      </div>
          </v-container>
        </v-col>
        <v-col justify="center" align="center" cols="12" md="6">
          <v-img max-width="90vw" contain
            src="https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/g3271.webp?alt=media&token=5ace8927-816d-4db9-834d-bb63b6c32cb6"
          >
          </v-img>
        </v-col>
      </v-row>
      <Gallery
      :images="images"
      :imageWidth="720"
      :imageHeight="450"
      :popUpMaxWidth="720"
      :mdCols="4"
      :smCols="4"
      :xsCols="6"
      :lgCols="2"
    />
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "HomeHero",

  
};
</script>

<style scoped>
.hero {
display: block;
box-sizing: border-box;
height: 500px;
background-color: #3d99dc;
clip-path: ellipse(162% 100% at 39.92% 0%);
}
.home-hero {
  background: url("https://images.unsplash.com/photo-1531834685032-c34bf0d84c77?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1097&q=80");
  background-size: cover;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 1000px rgba(0, 43, 99, 0.2);
  clip-path: ellipse(162% 100% at 39.92% 0%);
}
.gradient-text {
  background-image: linear-gradient(60deg, rgb(3, 124, 230), rgb(117, 177, 230));
  background-clip: text;
  color: transparent;
}
</style>
