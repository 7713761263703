<template>
  <ServicesHero/>
</template>

<script>
import ServicesHero from "@/components/ServicesHero";
export default {
  components: {
    ServicesHero,
  },

}
</script>

<style>

</style>