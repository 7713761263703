<template>
  <AboutHero/>
</template>

<script>
import AboutHero from "@/components/AboutHero";
export default {
  components: {
    AboutHero,
  },

}
</script>

<style>

</style>