<template>
  <GalleryHero/>
</template>

<script>
import GalleryHero from "@/components/GalleryHero";
export default {
  created () {
  },
  components: {
    GalleryHero,
  },

}
</script>

<style>

</style>