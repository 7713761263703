<template>
  <ContactHero/>
</template>

<script>
import ContactHero from "@/components/ContactHero";
export default {
  components: {
    ContactHero,
  },

}
</script>

<style>

</style>