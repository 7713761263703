<template>
  <v-container class="home-hero" fluid fill-height  style="min-height: 70vh">
    <v-container d-flex >
      <v-row  class="justify-center align-center flex-md-row-reverse">
        <v-col cols="12" md="6" >
          <v-container>
         <div >
             <div class="display-2 font-weight-black white--text text-left" style="text-shadow: 2px 2px 8px #000000">
          <span 
            >Our team is picked </span
          >
        </div>
        <div class="display-2 font-weight-black white--text text-left" style="text-shadow: 2px 2px 8px #000000">
          <span 
            >from only the best crew</span
          >
        </div>
        <div class="display-2 font-weight-black white--text text-left mb-3" style="text-shadow: 2px 2px 8px #000000">
          <span 
            >available on the market</span
          >
        </div>
        <div class="display-1 font-weight-bold white--text text-left" style="text-shadow: 2px 2px 8px #000000">
          <span style="color:  #d6ebff"
            >Learn about the people that make Concraft keep building up</span
          >
        </div>
      </div>
          </v-container>
        </v-col>
        <v-col justify="center" align="center" cols="12" md="6">
          <v-img max-width="90vw" contain
            src="https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/HOMEPART1.webp?alt=media&token=9ba76a58-1031-43bb-bc18-f0c126112dbf"
          >
          </v-img>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "HomeHero",
};
</script>

<style scoped>
.hero {
display: block;
box-sizing: border-box;
height: 500px;
background-color: #3d99dc;
clip-path: ellipse(162% 100% at 39.92% 0%);
}
.home-hero {
  background: url("https://images.unsplash.com/photo-1555945071-f36c590968bb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1925&q=80");
  background-size: cover;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 1000px rgba(0, 43, 99, 0.2);
  clip-path: ellipse(162% 100% at 39.92% 0%);
}
.gradient-text {
  background-image: linear-gradient(60deg, rgb(3, 124, 230), rgb(117, 177, 230));
  background-clip: text;
  color: transparent;
}
</style>
