<template>
  <div>
    <div>
      <v-container class="home-hero" fluid fill-height style="min-height: 70vh">
        <v-container d-flex>
          <v-row class="justify-center align-center flex-md-row-reverse">
            <v-col cols="12" md="6">
              <v-container>
                <div>
                  <div
                    class="display-2 font-weight-black white--text text-left"
                    style="text-shadow: 2px 2px 8px #000000"
                  >
                    <span>We have a promise </span>
                  </div>
                  <div
                    class="display-2 font-weight-black white--text text-left"
                    style="text-shadow: 2px 2px 8px #000000"
                  >
                    <span>to keep in service, </span>
                  </div>
                  <div
                    class="display-2 font-weight-black white--text text-left mb-3"
                    style="text-shadow: 2px 2px 8px #000000"
                  >
                    <span
                      ><span style="color: #C00102">CONTACT US</span> and find
                      out why.</span
                    >
                  </div>
                  <div
                    class="display-1 font-weight-bold white--text text-left"
                    style="text-shadow: 2px 2px 8px #000000"
                  >
                    <span style="color: #d6ebff"
                      >Use the form below to move up with Concraft</span
                    >
                  </div>
                </div>
              </v-container>
            </v-col>
            <v-col justify="center" align="center" cols="12" md="6">
              <v-img
                max-width="60vw"
                contain
                src="https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/g3068.webp?alt=media&token=6af6c03e-85c1-41f2-9108-f452bb9d972a"
              >
              </v-img>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </div>
    <div>
      <v-container>
        <v-row class="justify-center align-center mt-16">
          <v-col justify="center" align="center">
            <v-img contain src="https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/g3078.webp?alt=media&token=1c709870-276a-40f5-b4e9-e7b5cb45acf4">
            <v-card style="border-radius:12px" elevation="16" class="mt-8" max-width="640px">
              <v-card-title style="background-color: #005cb5; color: white">
                <h1 class="my-2" style="color:#C00102 ; text-shadow: 2px 2px 4px #000000 ; font-weight : 800">Get in touch now</h1>
              </v-card-title>
              <v-card-text class="my-8">
                <template>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-text-field
                      outlined
                      v-model="name"
                      :counter="10"
                      :rules="nameRules"
                      label="Fullname"
                      required
                    ></v-text-field>

                    <v-text-field
                      outlined
                      v-model="email"
                      :rules="emailRules"
                      label="E-mail"
                      required
                    ></v-text-field>

                    <v-autocomplete
                      outlined
                      allow-overflow
                      v-model="select"
                      :items="items"
                      :rules="[(v) => !!v || 'City is required']"
                      label="City"
                      required
                    ></v-autocomplete>

                    <v-textarea
                      outlined
                      label="Insert your message here"
                      required
                    ></v-textarea>

                    <v-checkbox
                      v-model="checkbox"
                      :rules="[(v) => !!v || 'You must agree to continue!']"
                      label="Make sure information is correct?"
                      required
                      color="success"
                    ></v-checkbox>

                    <v-btn
                      :disabled="!valid"
                      color="success"
                      class="mr-4"
                      @click="validate"
                    >
                      {{ buttonText }}
                    </v-btn>

                    <v-btn color="error" class="mr-4" @click="reset">
                      Reset Form
                    </v-btn>
                  </v-form>
                </template>
              </v-card-text>
            </v-card>
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactHero",
  data: () => ({
    buttonText: "Send",
    valid: true,
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    select: null,
    items: ["Klerksdorp", "Potchefstroom", "Johannesburg", "Pretoria"],
    checkbox: false,
  }),

  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped>
.hero {
  display: block;
  box-sizing: border-box;
  height: 500px;
  background-color: #3d99dc;
  clip-path: ellipse(162% 100% at 39.92% 0%);
}
.home-hero {
  background: url("https://images.unsplash.com/photo-1603573712193-0a9e3db27381?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80");
  background-size: cover;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 1000px rgba(0, 43, 99, 0.2);
  clip-path: ellipse(162% 100% at 39.92% 0%);
}
.gradient-text {
  background-image: linear-gradient(
    60deg,
    rgb(3, 124, 230),
    rgb(117, 177, 230)
  );
  background-clip: text;
  color: transparent;
}
</style>
