<template>
  <div>
    <div>
      <v-container class="home-hero" fluid fill-height style="min-height: 70vh">
        <v-container d-flex>
          <v-row class="justify-center align-center flex-md-row-reverse">
            <v-col cols="12" md="6">
              <v-container>
                <div>
                  <div
                    class="display-2 font-weight-black white--text text-left"
                    style="text-shadow: 2px 2px 8px #000000"
                  >
                    <span>We believe our scaffolding </span>
                  </div>
                  <div
                    class="display-2 font-weight-black white--text text-left"
                    style="text-shadow: 2px 2px 8px #000000"
                  >
                    <span>can out-perform any</span>
                  </div>
                  <div
                    class="display-2 font-weight-black white--text text-left mb-3"
                    style="text-shadow: 2px 2px 8px #000000"
                  >
                    <span>competitor in the area.</span>
                  </div>
                  <div
                    class="display-1 font-weight-bold white--text text-left"
                    style="text-shadow: 2px 2px 8px #000000"
                  >
                    <span style="color: #d6ebff"
                      >See what services we provide below.</span
                    >
                  </div>
                </div>
              </v-container>
            </v-col>
            <v-col justify="center" align="center" cols="12" md="6">
              <v-img
                max-width="75vw"
                contain
                src="https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/g3087.webp?alt=media&token=1967deea-51d0-4a24-add6-312e1986d07e"
              >
              </v-img>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </div>
    <v-container fluid fill-height style="min-height: 40vh">
      <v-container d-flex>
        <v-row>
          <v-col cols="12" md="6">
            <v-container>
              <div>
                <div
                  class="display-2 font-weight-black mb-2 text-left"
                  style="color: #c00102; text-shadow: 2px 2px 8px #000000"
                >
                  <span style="font-family: BIRDMAN"
                    >Premium Scaffolds and Scaffolding Equipment
                  </span>
                </div>
                <!-- <div class="display-2 font-weight-black  text-left">
          <span 
            >and services to be some</span
          >
        </div>
        <div class="display-2 font-weight-black text-left mb-3">
          <span 
            >of the best work in our area.</span
          >
        </div> -->
                <div class="headline text-left">
                  <span style="color: #d6ebff"
                    >We provide only best quality scaffolds to our clients, our
                    scaffolds have been refurbished using the top quality
                    rendering methods known in the scaffolding environment</span
                  >
                </div>
              </div>
            </v-container>
          </v-col>
          <v-divider
            class="hidden-sm-and-down"
            style="background-color: white"
            vertical
          />
          <v-divider class="hidden-md-and-up" style="background-color: white" />
          <v-col justify="center" align="center" cols="12" md="6">
            <v-container>
              <div>
                <div
                  class="display-2 font-weight-black text-left mb-2"
                  style="color: #c00102; text-shadow: 2px 2px 8px #000000"
                >
                  <span style="font-family: BIRDMAN"
                    >Risk free erection and dismantling services</span
                  >
                </div>
                <!-- <div class="display-2 font-weight-black  text-left">
          <span 
            >and services to be some</span
          >
        </div>
        <div class="display-2 font-weight-black text-left mb-3">
          <span 
            >of the best work in our area.</span
          >
        </div> -->
                <div class="headline text-left">
                  <span style="color: #d6ebff"
                    >Concraft offers full-scope scaffolding labour services,
                    where concraft goes through thorough employment processes to
                    ween out only the most loyal and skillful service
                    providers</span
                  >
                </div>
              </div>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-container
      class="about-background"
      fluid
      fill-height
      style="min-height: 45vh"
    >
      <v-container d-flex style="max-width: 1024px" class="mt-sm-12">
        <v-row class="justify-center align-center ml-16 flex-md-row-reverse">
          <v-col cols="12" md="6">
            <v-container>
              <div>
                <div
                  class="display-2 font-weight-black text-left"
                  style="text-shadow: 2px 2px 8px #000000; color: #008ae0"
                >
                  <span style="font-family: BIRDMAN">Concraft</span>
                </div>
                <div
                  class="display-1 font-weight-black white--text text-left"
                  style="text-shadow: 2px 2px 8px #000000"
                >
                  <span>also offers a free scaffolding assessment on site</span>
                </div>

                <div
                  class="headline font-weight-bold white--text text-left"
                  style="text-shadow: 2px 2px 8px #000000"
                >
                  <span style="color: #d6ebff"
                    >A premium service in the form of on-site free scaffolding inspection and assessments</span
                  >
                </div>
              </div>
            </v-container>
          </v-col>
          <v-col justify="center" align="center" cols="12" md="6">
            <v-card min-width="100%" elevation="0" color="transparent">
              <v-img
                max-width="280px"
                contain
              >
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-img class="positionleft"></v-img>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "HomeHero",
};
</script>

<style scoped>
.hero {
  display: block;
  box-sizing: border-box;
  height: 500px;
  background-color: #3d99dc;
  clip-path: ellipse(162% 100% at 39.92% 0%);
}
.home-hero {
  background: url("https://images.unsplash.com/photo-1636362006544-22445420703f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80");
  background-size: cover;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 1000px rgba(0, 43, 99, 0.2);
  clip-path: ellipse(162% 100% at 39.92% 0%);
}
.gradient-text {
  background-image: linear-gradient(
    60deg,
    rgb(3, 124, 230),
    rgb(117, 177, 230)
  );
  background-clip: text;
  color: transparent;
}
.about-background {
  /* background: url("https://images.unsplash.com/photo-1504964670878-71b73cec0ce1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1164&q=80"); */
  background-color: red;

  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 0 1000px rgba(0, 43, 99, 0.2);
  clip-path: ellipse(85% 56% at 51% 63%);
}
.positionleft {
  z-index: -1;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20vh;
  position: absolute;
  background: url("https://firebasestorage.googleapis.com/v0/b/pspotter-88145.appspot.com/o/path7471.webp?alt=media&token=28b1465f-64e8-4889-9588-87dc0389cdfa");
  background-size: contain;
}
</style>
